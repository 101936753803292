html, body, #root {
  height: 100%;
}

.fullscreen {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.page-header {
  padding-top: 40px;
  padding-left: 40px;
  padding-bottom: 40px;
}
.page-title {
  text-align: left;
  font-size: 20px;
  font-weight: 400;
}
.page-subtitle {
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  color: grey;
}
.page-body {
  display: flex;
  padding: 0 16px;
  flex-direction: column;
  gap: 10px;
}
.card {
  padding: 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.spacing {
  gap: 8px;
}
.menu-item {
  padding: 20px;
  background-color: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}
html, body {
  background-color: #ededed;
}
.weui-icon-filled-error {
  color: #F76260;
}
.weui-icon-filled-info {
  color: #10AEFF;
}
.weui-icon-filled-done2 {
  color: #09BB07;
}
.hint {
  text-align: center;
  color: grey;
  font-size: 12px;
}
.number {
  font-size: 16px;
  text-align: center;
  margin-left: 8px;
  font-weight: 500;
}
@media (prefers-color-scheme: dark) {
  .menu-item {
    background-color: #191919;
  }
  .card {
    background-color: #191919;
  }
  .weui-tabbar {
    background-color: #191919 !important;
  }
  .weui-tabbar:before {
    border-top: 1px solid #090909 !important;
  }
  .weui-loadmore_line {
    border-top: 1px solid #191919 !important;
  }
  .weui-loadmore__tips {
    background-color: #000000 !important;
  }
  html, body {
    background-color: #000000;
  }
}
